<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="category_list_main sabc_videos_page_body" v-else-if="is_data_ready">
        <!-- sabc tv show slider start -->
        <section class="sabc_video_showbanner_sec">
            <!-- <img src="/public/images/sabc_videos_showbanner_pic.png" alt="SABC Category Banner"/> -->
            <img :src="$storageURL+'images/video/'+category.image" :alt="category.title"/>
            <div class="sabc_video_showbanner_desc">
                <div class="container">
                    <h1>{{category.title}}</h1>
                    <p></p>
                    <router-link :to="{ name: 'videolising'}"> &lt; Back</router-link>
                </div>
            </div>
        </section>
        <!-- sabc tv show slider end  -->
        <!-- video area start -->
        <section class="container">
            <div class="sabc_videos_show_latest">
                <h2>Latest Episodes</h2>
                <div class="sabc_videos_show_latest_row" v-for="video in video_list"  v-if="video.image != null">
                    <router-link class="sabc_show_latest_video_outer" :to="{ name: 'video_player', params: { category: category.slug, player: video.slug}}">
                        <div class="sabc_show_latest_video">
                            <img class="play_icon" src="/assets/img/sabc_video_list_play_icon.png" alt="SABC Play Icon"/>
                            <!-- <img class="video_thumbnail" :src="base_url+video.image" :alt="video.title" loading="lazy"/> -->
                            <source media="(min-width: 575px)" :srcset="imagePath(base_url+video.image)">
                            <img class="video_thumbnail" :src="imagePath(base_url+video.image)" width="444" height="249"  :alt="video.title" loading="lazy">
                            
                        </div>
                    </router-link>
                    <div class="sabc_show_latest_desc">
                        <router-link :to="{ name: 'video_player', params: { category: category.slug, player: video.slug}}"><h3>{{video.title}}</h3></router-link>
                        <p></p>
                        <ul>
                            <li>
                                <p>
                                    <img src="/public/images/sabc_showvideos_timer.png" alt="SABC Show Video Time"/>
                                     {{new Date(video.duration*1000).toISOString().slice(11, 19)}}<i>|</i> {{video.release_date}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- video area end -->
    </div>
</template>

<script>
    export default {
        name: 'videolising',
        data() {
            return {
                base_url: '',
                video_list: [],
                category: [],
                is_data_ready:false
            }
        },
        components: {},
        mounted() {
            this.getVideoData();
        },
       watch: {
       },
        serverPrefetch() {
            this.getVideoData();
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.sabc_video_category_listing_meta_title);
                let description = this.$getSafe(() => ss.sabc_video_category_listing_meta_description);
                let keywords = this.$getSafe(() => ss.sabc_video_category_listing_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },

        methods: {
            getVideoData() {
                let params = {category: this.$route.params.category}
                return this.$axios.get(this.$GetVideoCategoryList, {params})
                        .then(response => {
                            if (response.data) {
                                if(response.data.video_list != undefined){
                                    this.base_url = response.data.base_url;
                                    this.video_list = response.data.video_list;
                                    this.category = response.data.category;
                                }
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
            imagePath(path){
                let lastSplit = path.replace(/\\/g,'/').replace(/.*\//, '').replace(/.jpeg/, '')+'_444x249.webp';
                return this.$storageURL+'images/video/'+lastSplit;
            }
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/videos_show.scss';
</style>
